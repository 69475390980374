import { extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

// These extensions are for generic error messages to common validations
// If you need a more specific error message, please create a custom
// validation inside the component you're using it for.
extend('email', {
    ...email,
    message: 'Invalid email',
});

extend('required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1,
        };
    },
    computesRequired: true,
    message: (fieldName) => {
        fieldName = fieldName
            .split('')
            .map((letter, i) => {
                if (i !== 0) {
                    return letter;
                }

                return letter.toUpperCase();
            })
            .join('');

        return `${fieldName} is required`;
    },
});

extend('phone', {
   validate(value) {
       // const regex = /^[0-9]\d{2}-?[0-9]\d{2}-?\d{4}$/ // old format
       // US phone number regex
       // build using https://blog.insycle.com/phone-number-formatting-crm
       const regex = /^((1|\+1|\+)[ -.]?)?((\d{3})|(\(\d{3}\)))[ -.]?(\d{3})[ -.]?(\d{4})$/
       return value.match(regex);
   },
    message: (fieldName) => {
        fieldName = fieldName
            .split('')
            .map((letter, i) => {
                if (i !== 0) {
                    return letter;
                }

                return letter.toUpperCase();
            })
            .join('');

        return `${fieldName} format: 000-000-0000`;
    }
});

// Mobile number with country code
extend('mobile', {
    validate(value) {
        // const regex = /^[0-9]\d{2}-?[0-9]\d{2}-?\d{4}$/ // old format
        // US phone number regex
        // build using https://blog.insycle.com/phone-number-formatting-crm
        const regex = /^\d{10,}$/
        return value.match(regex);
    },
     message: (fieldName) => {
         fieldName = fieldName
             .split('')
             .map((letter, i) => {
                 if (i !== 0) {
                     return letter;
                 }
 
                 return letter.toUpperCase();
             })
             .join('');
 
         return `${fieldName} format: 000-000-0000`;
     }
 });

// Mobile number with country code
extend('phoneNumber', {
    validate(value) {
        if (!value) return true;
        
        // Two regex patterns - one with country code, one without
        const intlRegex = /^\+?(\d{1,3})?[-. ]?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
        const localRegex = /^(\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4})$/;
        
        const digitsOnly = value.replace(/\D/g, '');
        const validLength = digitsOnly.length >= 10 && digitsOnly.length <= 15;
        
        return (intlRegex.test(value) || localRegex.test(value)) && validLength;
    },
    message: (fieldName) => `${fieldName} must be a valid phone number`
});

// Integer validation
extend('integer', {
    validate(value) {
        const regex = /^[0-9]*$/
        return String(value).match(regex);
    },
    message: `Only positive integer allowed`
});

// Allow up 1 decimal place
extend('OneDecimalPlace', {
    validate(value) {
        const regex = /^\d+(\.\d{1})?$/
        return String(value).match(regex);
    },
    message: `Allowed numbers upto 1 decimal place only`
});

// Three words separated by two dots
extend('ThreeWordsSeparationByTwo', {
    validate(value) {
        const regex = /^[a-zA-Z]+\.[a-zA-Z]+\.[a-zA-Z]+$/
        return String(value).match(regex);
    },
    message: (fieldName) => `Please enter valid ID for eg: us.zoom.videomeeting`
});

// Four words separated by three dots
extend('FourWordsSeparationByThree', {
    validate(value) {
        const regex = /^[a-zA-Z]+\.[a-zA-Z]+\.[a-zA-Z]+\.[a-zA-Z]+$/
        return String(value).match(regex);
    },
    message: (fieldName) => `Please enter valid ID for eg: us.zoom.video.meeting`
});

// Validate urls
extend('ValidateUrl', {
    validate(value) {
        const regex = '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        return String(value).match(regex);
    },
    message: `Invalid link. (example: https://www.abc.com)`
});